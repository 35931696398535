import { postData, getData, TOOL_ID } from './index';

import {
  ERROR_AUTH,
  MESSAGE_AUTH,
  USER_AUTH,
} from './types';

export async function loginAction(dispatch, obj) {
  const url = '/auth/login';
  let login;
  await postData(
    USER_AUTH, ERROR_AUTH, url, dispatch, { ...obj, tool: TOOL_ID }, false,
  ).then((response) => {
    login = response;
  });

  localStorage.setItem('token', login.token);

  dispatch({
    type: USER_AUTH,
    payload: login.user,
  });
}

export async function getUserAuthAction(dispatch) {
  const url = '/profile';
  let user;
  await getData(ERROR_AUTH, url, dispatch, true).then((response) => {
    user = response.user;
  });
  dispatch({
    type: USER_AUTH,
    payload: user,
  });
}

export async function forgotPasswordAction(dispatch, obj) {
  const url = '/auth/forgot-password';
  let mailSend;

  await postData(MESSAGE_AUTH, ERROR_AUTH, url, dispatch, obj, false).then((response) => {
    mailSend = response;
  });

  dispatch({
    type: MESSAGE_AUTH,
    payload: mailSend.message,
  });
}

export async function resetPasswordAction(dispatch, token, obj) {
  const url = `/auth/reset-password/${token}`;
  let reset;

  await postData(MESSAGE_AUTH, ERROR_AUTH, url, dispatch, obj, false).then((response) => {
    reset = response.message;
  });
  dispatch({
    type: MESSAGE_AUTH,
    payload: reset,
  });
}
