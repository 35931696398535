import React, {
  // forwardRef,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';

import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar, BsTrash } from 'react-icons/bs';
// GrFormView

import styles from './datepicker.module.scss';
import './datepicker.scss';

registerLocale('fr', fr);

const StyledDatePicker = (
  {
    placeholder = '',
    widthLabel = null,
    inline = false,
    label = null,
    value = null,
    onChange,
    clearDate,
    // inputRef,
  },
) => {
  function isSameDay(date1, date2) {
    if (date1 && date2
      && date1?.getDate() === date2?.getDate()
      && date1?.getMonth() === date2?.getMonth()
      && date1?.getFullYear() === date2?.getFullYear()
    ) {
      return true;
    } return false;
  }

  const [date, setDate] = useState();

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
  <div className={inline ? `container-custom-date-picker ${styles['date-picker']} ${styles.inline}` : `container-custom-date-picker ${styles['date-picker']}`}>
    { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
    <div className={`${styles['container-input']}`}>
      <DatePicker
        selected={date}
        onChange={(dateValue) => onChange(dateValue)}
        locale="fr"
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        popperPlacement="bottom-center"
        // customInput={
        //   <Input
        //     inputRef={inputRef}
        //     value={value}
        //   />}
        dayClassName={(d) => (isSameDay(d, date) ? `${styles.day} ${styles.selected}` : `${styles.day}`)}
      />
      <div className={styles['container-icon']} >
        <BsCalendar/>
      </div>
      {clearDate
        && <div
          className={styles['container-delete']}
          onClick={() => onChange()}
          >
            <BsTrash />
          </div>
        }
    </div>
  </div>
  );
};

// eslint-disable-next-line react/display-name
// const Input = forwardRef(({
//   onChange,
//   placeholder,
//   value,
//   inputRef,
//   id,
//   onClick,
// }) => (
//     <input
//         ref={inputRef}
//         onChange={onChange}
//         placeholder={placeholder}
//         value={value || null}
//         id={id}
//         onClick={onClick}
//     />
// ));

StyledDatePicker.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
};

StyledDatePicker.displayName = 'StyledDatePicker';

export default StyledDatePicker;
