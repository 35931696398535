import React, { useEffect } from 'react';
import './toaster.scss';

export default function Toaster({ toast, dispatch }) {
  useEffect(() => {
    if (toast && toast.message) {
      setTimeout(() => {
        dispatch({
          type: 'SET_TOAST',
          payload: null,
        });
      }, 4000);
    }
  }, [toast, dispatch]);

  return (
    <div className={`toaster ${toast && toast.message ? 'isActive' : ''} ${toast && toast.type === 'success' ? 'success' : ''} ${toast && toast.type === 'error' ? 'error' : ''}`}>
      <p>{toast && toast.message ? toast.message : ''}</p>
    </div>
  );
}
