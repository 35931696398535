import React from 'react';
import uniqid from 'uniqid';
import styles from './Boolean.module.scss';

export default function Boolean({
  value,
  isActive,
  label,
  className,
  options,
  isEdit,
  isRow,
  handleClick,
}) {
  return (
    <div className={`${styles.container} ${className ? styles[className] : ''}`}>
      {label && <label>{label}</label>}
      {isEdit
        && <div className={styles.options}>
          {options.map((opt) => (
              <button
                className={opt.value === value ? styles['is-active'] : ''}
                key={uniqid()}
                onClick={() => handleClick(opt.value)}
                >
                {opt.label}
              </button>
          ))}
        </div>
      }
      {!isEdit && value && <div className={`${styles.value} ${isActive ? styles['is-active'] : ''}`}>
        {isRow ? value : options?.find((o) => o.value === value)?.label}
      </div>}
      {!isEdit && !value && className !== 'status' && <div className={`${styles.value}`}>
        NON
      </div>}
    </div>
  );
}
