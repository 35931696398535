import React from 'react';
import { format } from 'date-fns';
import DatePicker from '../../../../lib/FormField/DatePicker';
import styles from './Dates.module.scss';

export default function DatesPrev({
  isEdit,
  watch,
  setValue,
}) {
  return (
    <section className={styles.container}>
      <h2>Submission and approval dates (provisional)</h2>
      <div className={styles.row}>
        <div className={styles.col}>
          <label>Soumission RMS</label>
          {isEdit
            ? <DatePicker
                value={watch('submissionRMSPrevisional') ? new Date(watch('submissionRMSPrevisional')) : null }
                onChange={(date) => setValue('submissionRMSPrevisional', date?.toUTCString() || null)}
                clearDate={() => setValue('submissionRMSPrevisional', null)}
                widthLabel={'30%'}
                placeholder="Date..."
              />
            : <p>{watch('submissionRMSPrevisional') && watch('submissionRMSPrevisional') instanceof Date ? format(new Date(watch('submissionRMSPrevisional')), 'dd.MM.yyyy') : 'N/A'}</p>
          }
        </div>
        <div className={styles.col}>
          <label>Approbation RMS</label>
            {isEdit
              ? <DatePicker
                  value={watch('validationRMSPrevisional') ? new Date(watch('validationRMSPrevisional')) : null }
                  onChange={(date) => setValue('validationRMSPrevisional', date?.toUTCString() || null)}
                  clearDate={() => setValue('validationRMSPrevisional', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('validationRMSPrevisional') && watch('validationRMSPrevisional') instanceof Date ? format(new Date(watch('validationRMSPrevisional')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
          </div>
        <div className={styles.col}>
          <label>Approbation ANSM</label>
            {isEdit
              ? <DatePicker
                  value={watch('validationANSMPrevisional') ? new Date(watch('validationANSMPrevisional')) : null }
                  onChange={(date) => setValue('validationANSMPrevisional', date?.toUTCString() || null)}
                  clearDate={() => setValue('validationANSMPrevisional', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('validationANSMPrevisional') && watch('validationANSMPrevisional') instanceof Date ? format(new Date(watch('validationANSMPrevisional')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
        </div>
      </div>
    </section>
  );
}
