import React from 'react';
import { format } from 'date-fns';
import DatePicker from '../../../../lib/FormField/DatePicker';
import styles from './Dates.module.scss';
import Boolean from '../../../../components/Boolean/Boolean';

export default function Dates({
  isEdit,
  watch,
  setValue,
}) {
  return (
    <section className={styles.container}>
      <h2>Submission and approval dates (real)</h2>
      <div className={styles.row}>
        <div className={styles.col}>
          <label>Soumission RMS</label>
          {isEdit
            ? <DatePicker
                value={watch('submissionRMS') ? new Date(watch('submissionRMS')) : null }
                onChange={(date) => setValue('submissionRMS', date?.toUTCString() || null)}
                clearDate={() => setValue('validationRMS', null)}
                widthLabel={'30%'}
                placeholder="Date..."
              />
            : <p>{watch('submissionRMS') && watch('submissionRMS') instanceof Date ? format(new Date(watch('submissionRMS')), 'dd.MM.yyyy') : 'N/A'}</p>
          }
        </div>
        <div className={styles.col}>
          <label>Approbation RMS</label>
            {isEdit
              ? <DatePicker
                  value={watch('validationRMS') ? new Date(watch('validationRMS')) : null }
                  onChange={(date) => setValue('validationRMS', date?.toUTCString() || null)}
                  clearDate={() => setValue('validationRMS', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('validationRMS') && watch('validationRMS') instanceof Date ? format(new Date(watch('validationRMS')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
          </div>
        <div className={styles.col}>
          <label>Approbation ANSM</label>
            {isEdit
              ? <DatePicker
                  value={watch('validationANSM') ? new Date(watch('validationANSM')) : null }
                  onChange={(date) => setValue('validationANSM', date?.toUTCString() || null)}
                  clearDate={() => setValue('validationANSM', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('validationANSM') && watch('validationANSM') instanceof Date ? format(new Date(watch('validationANSM')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
        </div>
        <div className={styles.col}>
          <label>ADC Approbation France</label>
            {isEdit
              ? <DatePicker
                  value={watch('ADCApprobationFrance') ? new Date(watch('ADCApprobationFrance')) : null }
                  onChange={(date) => setValue('ADCApprobationFrance', date?.toUTCString() || null)}
                  clearDate={() => setValue('ADCApprobationFrance', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('ADCApprobationFrance') instanceof Date ? format(new Date(watch('ADCApprobationFrance')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
        </div>
        <div className={styles.col}>
          <label>ADC Approbation CORP</label>
            {isEdit
              ? <DatePicker
                  value={watch('ADCApprobationCORP') ? new Date(watch('ADCApprobationCORP')) : null }
                  onChange={(date) => setValue('ADCApprobationCORP', date?.toUTCString() || null)}
                  clearDate={() => setValue('ADCApprobationCORP', null)}
                  widthLabel={'30%'}
                  placeholder="Date..."
                />
              : <p>{watch('ADCApprobationCORP') instanceof Date ? format(new Date(watch('ADCApprobationCORP')), 'dd.MM.yyyy') : 'N/A'}</p>
            }
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Boolean
            label='Information de sécurité'
            isActive={true}
            options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
            isEdit={isEdit}
            handleClick={(val) => setValue('safetyNotice', val)}
            value={watch('safetyNotice')}
          />
        </div>
        <div className={styles.col}>
          <Boolean
            label='Information de sécurité (pub)'
            isActive={true}
            options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
            isEdit={isEdit}
            handleClick={(val) => setValue('safetyPub', val)}
            value={watch('safetyPub')}
          />
        </div>
      </div>
    </section>
  );
}
