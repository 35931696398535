import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RiFileDownloadFill } from 'react-icons/ri';
import ReactExport from 'react-data-export';

import { exportVariationsAction } from '../../actions/variationsActions';
import { EXPORT_VARIATION } from '../../actions/types';

import Button from '../../lib/HooksFormFields/Button';

import styles from './ExportButton.module.scss';

export default function ExportButton() {
  const { exportData } = useSelector((state) => state.variations);
  const dispatch = useDispatch();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;

  function renderExcelToExport() {
    if (exportData?.dataToExport) {
      return exportData.dataToExport.map((sheet, i) => {
        const styledDataSet = {
          columns: sheet.sheetHeader.map((h) => ({
            title: h,
            widthPx: { auto: 1 },
            style: {
              font: { bold: true },
              alignment: {
                wrapText: true, horizontal: 'center', vertical: 'top',
              },
            },
          })),
          data: [],
        };
        sheet.sheetData.forEach((rowData) => {
          const row = [];
          sheet.sheetHeader.forEach((key) => {
            row.push({
              value: rowData[key] || '',
              style: {
                alignment: {
                  wrapText: true, horizontal: 'center', vertical: 'top',
                },
              },
            });
          });
          styledDataSet.data.push(row);
        });
        if (styledDataSet?.columns) {
          return <ExcelSheet key={i} dataSet={[styledDataSet]} name={sheet.sheetName}/>;
        }
        return null;
      });
    }
    return null;
  }
  return (
    <div className={styles.container}>
        {exportData?.fileName
          ? <ExcelFile
            element={
              <Button
                primary
                handleClick={() => dispatch({
                  type: EXPORT_VARIATION,
                  payload: null,
                })}
                >
                <RiFileDownloadFill size={20}/> TÉLÉCHARGER
              </Button>
              }
            filename={exportData.fileName}
          >
          {renderExcelToExport()}
          </ExcelFile>
          : <Button
          handleClick={() => exportVariationsAction(dispatch)}
          primary
          >
          EXPORTER
        </Button>
      }
    </div>
  );
}
