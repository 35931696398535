import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Textarea from '../../../../lib/HooksFormFields/Textarea';
import Button from '../../../../lib/HooksFormFields/Button';

import styles from './Comments.module.scss';

export default function Comments({
  watch,
  control,
  setValue,
  submit,
}) {
  const { userAuth } = useSelector((state) => state.auth);

  function handleSubmit() {
    const comment = {
      author: userAuth._id,
      date: new Date().toUTCString(),
      comment: watch('new-comment'),
    };
    submit({ comments: [...watch('comments'), comment] });
    setValue('new-com', '');
  }

  return (
    <section className={styles.container}>
      <h2>Commentaires</h2>
      {watch('comments')?.map((c) => (
        <div key={c._id} className={styles.comment}>
          <pre>{c.comment}</pre>
          <div className={styles.header}>
          <p>{format(new Date(c.date), 'dd.MM.yyyy')}</p>
            {c?.author?.profile?.firstName && c?.author?.profile?.lastName
              ? <p>
                {c?.author?.profile?.firstName?.charAt(0)}.{c?.author?.profile?.lastName?.charAt(0)}
              </p>
              : <p></p>
            }
          </div>
        </div>
      ))}
      <div className={styles.textarea}>
      <Textarea
        name={'new-comment'}
        control={control}
        className={'primary'}
        placeholder={'Votre commentaire'}
        />
      </div>
      {watch('new-comment')
        && <Button
          handleClick={handleSubmit}
          >
          ENVOYER
        </Button>
      }
    </section>
  );
}
