/* eslint-disable no-case-declarations */
import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getVariationsListAction, getDrugsListAction } from '../../actions/variationsActions';

import SortButton from '../../lib/SortButton';
import Button from '../../lib/HooksFormFields/Button';
import VariationRow from '../../components/VariationRow/VariationRow';
import Filters from '../../components/VariationsFilters/Filters';

import styles from './VariationsList.module.scss';
import ExportButton from '../../components/ExportButton/ExportButton';
import { TOOL_ID } from '../../actions';

export default function VariationsList() {
  const dispatch = useDispatch();
  const { variationsList, variationsfilters, drugs } = useSelector((state) => state.variations);
  const { userAuth } = useSelector((state) => state.auth);
  const role = userAuth?.tools?.find((p) => p.tool === TOOL_ID)?.role;
  const contentRef = useRef();

  const initSortState = {
    number: true,
    isAMMUpdated: true,
    submissionRMS: true,
    validationRMS: true,
    validationANSM: true,
    impact: true,
    status: true,
    product: true,
  };

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filtredList, setFiltredList] = useState();
  const [sortedList, setSortedList] = useState();
  const [scrollPos, setScrollPos] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  const getVariations = useCallback(() => {
    getVariationsListAction(dispatch);
    getDrugsListAction(dispatch);
  }, [dispatch]);

  function findDrugName(id) {
    return drugs?.find((d) => d._id === id)?.name || null;
  }

  function sortList(type, isReverse) {
    let list = [...sortedList];
    switch (type) {
      case 'number':
        list = list.sort((a, b) => {
          if (a.number < b.number) return -1;
          if (a.number > b.number) return 1;
          return 0;
        });
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, number: isReverse });
        break;

      case 'impact':
        list = list.sort((a, b) => {
          if (b.impact < a.impact) return -1;
          if (b.impact > a.impact) return 1;
          return 0;
        });
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, impact: isReverse });
        break;

      case 'isAMMUpdated':
        list = list.sort((a, b) => a.isAMMUpdated - b.isAMMUpdated);
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, isAMMUpdated: isReverse });
        break;

      case 'submissionRMS':
        list = list.sort((a, b) => {
          const aDate = a.submissionRMS
            ? new Date(a.submissionRMS) : new Date(a.submissionRMSPrevisional);
          const bDate = b.submissionRMS
            ? new Date(b.submissionRMS) : new Date(b.submissionRMSPrevisional);
          return aDate - bDate;
        });
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, submissionRMS: isReverse });
        break;

      case 'validationRMS':
        list = list.sort((a, b) => {
          const aDate = a.validationRMS
            ? new Date(a.validationRMS) : new Date(a.validationRMSPrevisional);
          const bDate = b.validationRMS
            ? new Date(b.validationRMS) : new Date(b.validationRMSPrevisional);
          return aDate - bDate;
        });
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, validationRMS: isReverse });
        break;

      case 'validationANSM':
        list = list.sort((a, b) => {
          const aDate = a.validationANSM
            ? new Date(a.validationANSM) : new Date(a.validationANSMPrevisional);
          const bDate = b.validationANSM
            ? new Date(b.validationANSM) : new Date(b.validationANSMPrevisional);
          return aDate - bDate;
        });
        setFiltredList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, validationANSM: isReverse });
        break;

      case 'status':
        list = list.sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, status: isReverse });
        break;

      case 'product':
        list = list.sort((a, b) => {
          if (findDrugName(a.drug) < findDrugName(b.drug)) return -1;
          if (findDrugName(a.drug) > findDrugName(b.drug)) return 1;
          return 0;
        });
        setSortedList(isReverse ? list.reverse() : list);
        setSortByReverse({ ...initSortState, product: isReverse });
        break;

      default:
        break;
    }
  }

  function handleScroll(e) {
    if (!variationsfilters.search) {
      setScrollPos(e.target.scrollTop);
    }
  }

  useEffect(() => {
    getVariations();
  }, []);

  useEffect(() => {
    if (variationsList) {
      const initList = variationsList.sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) return -1;
        if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
        return 0;
      });
      setFiltredList(initList.reverse());
    }
    // eslint-disable-next-line
  },[variationsList]);

  useEffect(() => {
    if (!variationsfilters?.search && contentRef?.current) {
      setScrolled(!scrolled);
    }
  }, [variationsfilters?.search]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: scrollPos,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [contentRef.current, scrolled]);

  useEffect(() => {
    const list = filtredList?.filter((v) => {
      if (variationsfilters.status.length > 0) {
        let found = false;
        variationsfilters.status.forEach((key) => {
          if (v.status === key) found = true;
        });
        return found ? v : null;
      }
      return v;
    })
      .filter((v) => {
        if (variationsfilters.relatedTo.length > 0) {
          let found = false;
          variationsfilters.relatedTo.forEach((key) => {
            if (v[key] === true) found = true;
          });
          return found ? v : null;
        }
        return v;
      }).filter((v) => {
        if (variationsfilters.impact.length > 0) {
          let found = false;
          variationsfilters.impact.forEach((key) => {
            if (v.impact === key) found = true;
          });
          return found ? v : null;
        }
        return v;
      }).filter((v) => {
        if (variationsfilters.products.length > 0) {
          let found = false;
          variationsfilters.products.forEach((key) => {
            if (v.drug === key) found = true;
          });
          return found ? v : null;
        }
        return v;
      })
      .filter((v) => {
        if (variationsfilters.safety.length === 1) {
          let found = false;
          if (variationsfilters.safety[0] === 'with') {
            if (v.safetyNotice || v.safetyPub) found = true;
          }
          if (variationsfilters.safety[0] === 'without') {
            if (!v.safetyNotice && !v.safetyPub) found = true;
          }
          return found ? v : null;
        }
        if (variationsfilters.safety.length === 0) {
          return null;
        }
        return v;
      })
      .filter((v) => {
        if (!variationsfilters?.search) return v;
        if (v?.number?.toLowerCase().includes(variationsfilters?.search?.toLowerCase())) {
          return v;
        }
        return null;
      });
    setSortedList(list);
  }, [filtredList, variationsfilters]);

  return (
    <>
      <Filters />
      <div className={styles['btn-container']}>
        {role === 'Admin' && <ExportButton />}
        {role !== 'Member' && <Link to={'/variation/new'}>
          <Button>
            NOUVELLE VARIATION
          </Button>
        </Link>
        }
      </div>
      <div className={`${styles.container}`}>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.label}`}>
            <SortButton
              name={'Numéro de la variation'}
              handleClick={() => sortList('number', !sortByReverse.number)}
              state={sortByReverse.number}
              className={`${styles.col} ${styles.number}`}/>
            <SortButton
              name={'Produit'}
              handleClick={() => sortList('product', !sortByReverse.product)}
              state={sortByReverse.product}
              className={`${styles.col}`}/>
            <SortButton
              name={'ADC modifiés'}
              handleClick={() => sortList('isAMMUpdated', !sortByReverse.isAMMUpdated)}
              state={sortByReverse.isAMMUpdated}
              className={`${styles.col}`}/>
            <SortButton
              name={'Soumission RMS'}
              handleClick={() => sortList('submissionRMS', !sortByReverse.submissionRMS)}
              state={sortByReverse.submissionRMS}
              className={`${styles.col}`}/>
            <SortButton
              name={'Approbation RMS'}
              handleClick={() => sortList('validationRMS', !sortByReverse.validationRMS)}
              state={sortByReverse.validationRMS}
              className={`${styles.col}`}/>
            <SortButton
              name={'Approbation ANSM'}
              handleClick={() => sortList('validationANSM', !sortByReverse.validationANSM)}
              state={sortByReverse.validationANSM}
              className={`${styles.col}`}/>
            <SortButton
              name={'Impact sur les éléments de communication'}
              handleClick={() => sortList('impact', !sortByReverse.impact)}
              state={sortByReverse.impact}
              className={`${styles.col}`}/>
            <SortButton
              name={'Statut'}
              handleClick={() => sortList('status', !sortByReverse.status)}
              state={sortByReverse.status}
              className={`${styles.col} ${styles.status}`}/>
          </div>
        </div>
        <div
          onScroll={handleScroll}
          ref={contentRef}
          className={`${styles.content}`}
        >
          {sortedList?.map((v) => (<VariationRow
            key={v._id}
            styles={styles}
            drug={findDrugName(v.drug)}
            variation={v}
          />))}
        </div>
      </div>
    </>
  );
}
