import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styles from './styled-select.module.scss';
import styleJs from './style';

const StyledSelect = ({
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  options,
  value,
  handleChange,
}) => (
    <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <Select
        options={options}
        value={options.find((opt) => opt.value === value)}
        onChange={(opt) => handleChange(opt.value)}
        className={`${styles.select}`}
        placeholder={placeholder}
        styles={styleJs}
      />
    </div>
);

StyledSelect.propTypes = {
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
