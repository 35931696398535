import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import Button from '../../../../lib/HooksFormFields/Button';
import ClockStopRow from '../../../../components/ClockStopRow/ClockStopRow';
import styles from './ClockStops.module.scss';

export default function ClockStops({
  watch,
  control,
  setValue,
  submit,
  isEdit,
}) {
  const clockstops = watch('clockstops') ? watch('clockstops') : [];
  console.log(clockstops);
  return (
    <div className={styles.container}>
      {clockstops?.map((c, i) => (
        <ClockStopRow
          key={i}
          index={i}
          data={c}
          isEdit={isEdit}
          list={clockstops}
          setValue={setValue}
          control={control}
          submit={submit}
          />
      ))}
      {isEdit && <div className={styles.btn}>
        <Button
          handleClick={() => setValue('clockstops', [...clockstops, { name: 'CS', startDate: new Date() }])}
          type={'secondary'}
          >
          <AiOutlineClockCircle size={30} />CLOCK STOP
        </Button>
      </div>}
    </div>
  );
}
