export const detailsOptions = [
  {
    value: 'isRCPUpdated',
    label: 'RCP',
  },
  {
    value: 'isLabellingUpdated',
    label: 'packaging',
  },
  {
    value: 'isNoticeUpdated',
    label: 'Notice',
  },
];

export const statusOptions = [
  {
    value: 'In progress',
    label: 'In progress',
  },
  {
    value: 'Submitted',
    label: 'Submitted',
  },
  {
    value: 'Approved',
    label: 'Approved',
  },
  {
    value: 'Refused',
    label: 'Refused',
  },
];

export const comImpactOptions = [
  { value: 'Majeur', label: 'Majeur' },
  { value: 'Mineur', label: 'Mineur' },
  { value: 'Non', label: 'Non' },
];
