import { createStore, combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import variationsReducer from '../reducers/variationsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  variations: variationsReducer,
});

export default createStore(rootReducer);
