import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HiLockClosed } from 'react-icons/hi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { format } from 'date-fns';
import { BsInfoCircleFill } from 'react-icons/bs';
import Boolean from '../Boolean/Boolean';

export default function VariationRow({ variation, styles, drug }) {
  const [isInClockstop, setIsClockstop] = useState(false);
  const [panelIsOpen, setPanelIsOpen] = useState(false);

  const panelRef = useRef();

  function handlePanelToggle(e) {
    e.preventDefault();
    setPanelIsOpen(!panelIsOpen);
  }

  function getBooleanValue(value) {
    if (typeof value !== 'boolean') {
      return 'N/A';
    }
    return value === true ? 'Oui' : 'Non';
  }

  useEffect(() => {
    if (variation?.clockstops.length > 0) {
      variation.clockstops.forEach((stop) => {
        const today = new Date();
        if (!isInClockstop
          && new Date(stop?.startDate) <= today
          && new Date(stop?.endDate) >= today) {
          setIsClockstop(true);
        }
        if (!isInClockstop
          && new Date(stop?.startDate) <= today
          && !stop?.endDate) {
          setIsClockstop(true);
        }
      });
    }
  }, [variation?.clockstops]);

  return (
    <div className={styles['row-container']}>
      <Link to={`/variation/${variation._id}`} className={`${styles.row}`}>
        {isInClockstop && <div className={styles.clock}>
          <AiOutlineClockCircle size={22} />
        </div>}
        <div className={`${styles.col} ${styles.number}`}>
          <span>
          {(variation.safetyNotice || variation.safetyPub)
            && <HiLockClosed />
          }
          {variation.number}
          </span>
          {variation.description && <button
            onClick={(e) => handlePanelToggle(e)}
            className={styles.toggle}>
              <BsInfoCircleFill size={20}/>
            </button>
          }
        </div>
        <div className={`${styles.col}`}>
          {drug && drug}
        </div>
        <div className={`${styles.col}`}>
          <Boolean
            isRow
            isActive={variation.isADC}
            value={getBooleanValue(variation.isADC)}
          />
        </div>
        <div className={`${styles.col}`}>
          {variation.submissionRMS || variation.submissionRMSPrevisional
            ? <div className={`${styles.icon} ${styles.submition}`} />
            : null
          }
          <p>
            {variation.submissionRMS
              ? format(new Date(variation.submissionRMS), 'dd.MM.yyyy')
              : <span className={styles.prev}>{variation.submissionRMSPrevisional && format(new Date(variation.submissionRMSPrevisional), 'dd.MM.yyyy')}</span>
            }
          </p>
        </div>
        <div className={`${styles.col}`}>
          {variation.validationRMS || variation.validationRMSPrevisional
            ? <div className={`${styles.icon} ${styles.date}`} />
            : null
          }
          <p>
            {variation.validationRMS
              ? format(new Date(variation.validationRMS), 'dd.MM.yyyy')
              : <span className={styles.prev}>{variation.validationRMSPrevisional && format(new Date(variation.validationRMSPrevisional), 'dd.MM.yyyy')}</span>
            }
          </p>
        </div>
        <div className={`${styles.col} `}>
          {variation.validationANSM || variation.validationANSMPrevisional
            ? <div className={`${styles.icon} ${styles['approbation-ANSM']}`} />
            : null
          }
          <p>
            {variation.validationANSM
              ? format(new Date(variation.validationANSM), 'dd.MM.yyyy')
              : <span className={styles.prev}>{variation.validationANSMPrevisional && format(new Date(variation.validationANSMPrevisional), 'dd.MM.yyyy')}</span>
            }
          </p>
        </div>
        <div className={`${styles.col}`}>
          <p>{variation?.impact}</p>
        </div>
        <div className={`${styles.col} ${styles.status} ${variation.status === 'Approved' ? styles['is-active'] : ''}`}>
          <p>{variation.status}</p>
        </div>
      </Link>
      <div className={styles.panel} style={{
        height: panelIsOpen && panelRef.current ? panelRef.current.clientHeight : 0,
      }}>
        <div ref={panelRef} className={styles['panel-content']}>
          <p>{variation.description}</p>
        </div>
      </div>
    </div>
  );
}
