import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Textarea from '../../../../lib/HooksFormFields/Textarea';
import Button from '../../../../lib/HooksFormFields/Button';

import styles from './Comments.module.scss';

export default function ComElements({
  watch,
  control,
  setValue,
  isEdit,
  submit,
}) {
  const { userAuth } = useSelector((state) => state.auth);

  function handleSubmit() {
    const comment = {
      author: userAuth._id,
      date: new Date().toUTCString(),
      communication: watch('new-com'),
    };
    submit({ communications: [...watch('communications'), comment] });
    setValue('new-com', '');
  }

  return (
    <section className={styles.container}>
      <h2>Eléments de communication</h2>
      {watch('communications')?.map((c) => (
        <div key={c._id} className={styles.comment}>
          <p>{c.communication}</p>
          <div className={styles.header}>
          <p>{format(new Date(c.date), 'dd.MM.yyyy')}</p>
            {c?.author?.profile?.firstName && c?.author?.profile?.lastName
              ? <p>
                {c?.author?.profile?.firstName?.charAt(0)}.{c?.author?.profile?.lastName?.charAt(0)}
              </p>
              : <p></p>
            }
          </div>
        </div>
      ))}
      {isEdit && <>
        <div className={styles.textarea}>
        <Textarea
          name={'new-com'}
          control={control}
          className={'primary'}
          placeholder={'Votre message'}
          />
        </div>
        {watch('new-com')
          && <Button
            handleClick={handleSubmit}
            >
            ENVOYER
          </Button>
        }
      </>}
    </section>
  );
}
