// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const SET_TOAST = 'SET_TOAST';
// VARIATIONS ACTIONS
export const ERROR_VARIATIONS = 'ERROR_VARIATIONS';
export const GET_VARIATIONS = 'GET_VARIATIONS';
export const GET_VARIATION = 'GET_VARIATION';
export const PUT_VARIATION = 'PUT_VARIATION';
export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';
export const POST_VARIATION = 'POST_VARIATION';
export const DELETE_VARIATION = 'DELETE_VARIATION';
export const SET_VARIATION_FILTERS = 'SET_VARIATION_FILTERS';
export const GET_DRUGS = 'GET_DRUGS';
export const EXPORT_VARIATION = 'EXPORT_VARIATION';
export const SET_PLANNING_FILTERS = 'SET_PLANNING_FILTERS';
