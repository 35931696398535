import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';

import { postFileAction, deleteFileAction } from '../../../../actions/variationsActions';

import InputFile from '../../../../lib/FormField/InputFile/InputFile';

import styles from './Files.module.scss';
import FileItem from '../../../../components/FileItem/FileItem';

export default function Files({
  control,
  submit,
  isEdit,
}) {
  const dispatch = useDispatch();
  const { file, variation, error } = useSelector((state) => state.variations);
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type: 'POST_FILE',
      payload: null,
    });
    dispatch({
      type: 'ERROR_VARIATIONS',
      payload: null,
    });
  }, [dispatch]);

  function handleChangeFile(fileData) {
    if (!fileData) {
      return null;
    }

    setFileError();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('title', fileData.name);
    formData.append('description', `document ${fileData.name}`);
    formData.append('alt', `${fileData.name.split(' ').join('-')}-file`);
    return postFileAction(dispatch, formData);
  }

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    };
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (file && file._id) {
      setIsLoading(false);
      submit({ files: [...variation.files.map((d) => d._id), file._id] });
      clearFile();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (error && error.data && error.data.error) {
      if (error.data.error === 'Extension not allowed.') {
        setFileError("Ce type de fichier n'est pas autorisé");
        setIsLoading(false);
        clearFile();
      }
    }
    // eslint-disable-next-line
  },[error]);

  function deleteFile(id) {
    deleteFileAction(dispatch, id);
    submit({ files: variation.files.map((d) => d._id).filter((f) => f !== id) });
  }

  return (
    <div className={styles.container}>
      <h2>Fichiers</h2>
      <div className={styles.files}>
        {variation?.files.map((f) => (<FileItem
          key={f._id}
          file={f}
          styles={styles}
          deleteFile={deleteFile}
          />
        ))}
      </div>
      <div className={styles.input}>
      {isEdit && <Controller
        name="file"
        rules={{}}
        control={control}
        defaultValue={null}
        render={() => <InputFile
            name="file"
            isLoading={isLoading}
            withErrors={true}
            defaultValue={null}
            onChange={(files) => handleChangeFile(files[0])}
            error={fileError}
            />}
      />}
      </div>
    </div>
  );
}
