import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { BiSearch } from 'react-icons/bi';
import { SET_VARIATION_FILTERS } from '../../actions/types';
import FilterList from './partials/FilterList';

import styles from './filters.module.scss';
import { comImpactOptions, detailsOptions, statusOptions } from '../../utils/constants';

export default function Filters({ isDisabled }) {
  const { variationsfilters, drugs } = useSelector((state) => state.variations);
  const dispatch = useDispatch();

  const [filterIsOpen, setFiltersIsOpen] = useState(false);
  const [search, setSearch] = useState();

  function getClassNames() {
    let classNames = styles.filters;
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames;
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`;
    }
    return classNames;
  }

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_VARIATION_FILTERS,
      payload: { ...variationsfilters, [key]: value },
    });
  }

  return (
    <div className={getClassNames()}>
      <button className={styles['btn-filters']} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen ? <RiArrowLeftSLine size={28} color={'#FFFFFF'}/> : <BsFilterLeft size={30} color={'#FFFFFF'}/> }
      </button>
      {filterIsOpen
        && <>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <input
              value={search || '' }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles['btn-search']}
              onClick={() => handleChangeFilters('search', search || '')}>
              <BiSearch size={18} color={'#FFFFFF'}/>
            </button>
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Produits'}
              styles={styles}
              list={drugs.map((d) => ({ value: d._id, label: d.name })) }
              activeList={variationsfilters.products}
              setActiveList={(val) => handleChangeFilters('products', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'information de sécurité'}
              styles={styles}
              list={[
                { value: 'with', label: 'with' },
                { value: 'without', label: 'without' },
              ]}
              activeList={variationsfilters.safety}
              setActiveList={(val) => handleChangeFilters('safety', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Related to'}
              styles={styles}
              list={detailsOptions}
              activeList={variationsfilters.relatedTo}
              setActiveList={(val) => handleChangeFilters('relatedTo', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Statut'}
              styles={styles}
              list={statusOptions}
              activeList={variationsfilters.status}
              setActiveList={(val) => handleChangeFilters('status', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Impact sur la communication'}
              styles={styles}
              list={comImpactOptions}
              activeList={variationsfilters.impact}
              setActiveList={(val) => handleChangeFilters('impact', val)}
              />
          </div>
        </>
      }
    </div>
  );
}
