import React, { useEffect } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiLogOut } from 'react-icons/fi';
import logo from '../../assets/images/logo.png';

import styles from './Nav.module.scss';

export default function Nav({ logout }) {
  const { userAuth, error } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const role = userAuth?.products?.find((item) => item.product === 'Variocta')?.role;

  useEffect(() => {
    if (userAuth && error === 401) {
      logout();
    }
  }, [error]);

  return (
    <>
      <nav className={`${styles.nav} ${!role && location.pathname === '/login' ? '' : styles['nav-is-active']}`}>
        <div className={styles.content}>

          <NavLink to="/" className={styles.logo}>
            <img src={logo} alt="medocta" />
            <h2>VARIOCTA</h2>
          </NavLink>

          <div className={styles.links}>
            {location.pathname === '/planning'
              ? <a onClick={() => history.goBack()}>
                  Variations
              </a>
              : <NavLink to="/variation" activeClassName={styles['is-active']} >Variations</NavLink>
            }
            <NavLink to="/planning" activeClassName={styles['is-active']} >Planning</NavLink>
          </div>

          <div className={styles.icons}>

            {/* <NavLink to="/account">
              <FiUser size={22} color={'#192243'}/>
            </NavLink> */}

            <button className={styles.logout} onClick={() => logout()}>
              <FiLogOut size={22} color={'#192243'}/>
            </button>

          </div>

        </div>
      </nav>
    </>

  );
}
