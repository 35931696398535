import React from 'react';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import { AiOutlineClockCircle } from 'react-icons/ai';
import styles from './Step.module.scss';

export default function Step({
  stepStyles,
  className,
  number,
  variation,
  label,
  date,
  dates,
}) {
  function prevStyle() {
    const array = [];
    for (let i = 0; i < (stepStyles.width / 2) / 6; i++) {
      array.push('prev-item');
    }
    // eslint-disable-next-line prefer-template
    return array.map((a, i) => <div key={'prev-item' + i} className={styles['prev-item']}></div>);
  }

  return (
    <div
      className={`${styles.step}`}
      style={stepStyles}
    >
      <div className={`${styles[className]}`}>
        {className === 'duration-prev'
          && <div className={styles['prev-container']} style={{ width: stepStyles.width }}>
            {prevStyle()}
          </div>
        }
      </div>
      {label && date && isValid(date)
        && <p className={styles.tooltip}>
        <label>{label}</label><br/>
        {format(new Date(date), 'dd.MM.yyyy')}
      </p>}
      {number
        && <p><Link to={`/variation/${variation}`} className={className === 'duration-prev' ? styles.prev : ''}>{number}</Link></p>
      }
      {className === 'clockstop'
        && <div className={styles['clock-label']}><AiOutlineClockCircle size={15}/>{label}</div>
      }
      {className === 'clockstop'
        && <p className={`${styles.tooltip} ${styles.clock}`}>
        <label>{label}</label><br/>
        {format(new Date(dates.start), 'dd.MM.yyyy')} - {format(new Date(dates.end), 'dd.MM.yyyy')}
      </p>}
    </div>
  );
}
