import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { MdChevronLeft } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import Boolean from '../../components/Boolean/Boolean';
import Button from '../../lib/HooksFormFields/Button';
import {
  getVariationAction,
  putVariationAction,
  postVariationAction,
  deleteVariationAction,
  getDrugsListAction,
} from '../../actions/variationsActions';

import styles from './Variation.module.scss';
import Header from './sections/Header/Header';
import Dates from './sections/Dates/Dates';
import Details from './sections/Details/Details';
import Comment from './sections/Comments/Comments';
import ComElements from './sections/ComElements/ComElements';
import Files from './sections/Files/Files';
import ClockStops from './sections/ClockStops/ClockStops';
import DatesPrev from './sections/DatesPrev/DatesPrev';
import { comImpactOptions, statusOptions } from '../../utils/constants';
import { TOOL_ID } from '../../actions';

export default function Variation() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { toast, userAuth } = useSelector((state) => state.auth);
  const { variation, drugs } = useSelector((state) => state.variations);
  const mainRef = useRef();
  const {
    // handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    // setError,
  } = useForm();
  const role = userAuth?.tools?.find((p) => p.tool === TOOL_ID)?.role;
  const [isEdit, setIsEdit] = useState(false);

  const getVariation = useCallback(() => {
    getVariationAction(dispatch, id);
  }, [dispatch]);

  const getDrugs = useCallback(() => {
    getDrugsListAction(dispatch);
  }, [dispatch]);

  function initValues(object) {
    /* eslint-disable-next-line */
    for (const value of Object.entries(object)) {
      const values = ['status', 'impact', 'isLabellingUpdated', 'number', 'safetyNotice', 'safetyPub', 'isADC', 'type', 'communications', 'comments', 'isAMMUpdated', 'isDerogatoryAMM', 'description', 'isRCPUpdated', 'isNoticeUpdated', 'change', 'ref', 'clockstops', 'drug'];
      const dates = ['submissionRMS', 'validationRMS', 'validationANSM', 'ADCApprobationFrance', 'ADCApprobationCORP', 'submissionRMSPrevisional', 'validationRMSPrevisional', 'validationANSMPrevisional'];
      if (values.find((v) => v === value[0])) {
        setValue(value[0], value[1]);
      }
      if (dates.find((v) => v === value[0] && value[1])) {
        setValue(value[0], new Date(value[1]));
      }
    }
    return null;
  }

  function handleChangePackaging(key, val) {
    setValue(key, val);
    if (key === 'isLabellingUpdated') {
      if (val) {
        setValue('isADC', true);
      } else if (watch('isNoticeUpdated') === false) {
        setValue('isADC', false);
      }
    }
    if (key === 'isNoticeUpdated') {
      if (val) {
        setValue('isADC', true);
      } else if (watch('isLabellingUpdated') === false) {
        setValue('isADC', false);
      }
    }
  }

  function submitVariation(val) {
    let values = getValues();
    if (val) {
      values = { ...values, ...val };
    }
    console.log(values);
    if (variation?._id) {
      return putVariationAction(dispatch, variation?._id, values);
    }
    return postVariationAction(dispatch, values);
  }

  useEffect(() => {
    getDrugs();
    if (id) {
      getVariation();
    } else {
      setValue('isADC', false);
      setValue('isAMMUpdated', false);
      setValue('isDerogatoryAMM', false);
      setValue('isLabellingUpdated', false);
      setValue('isNoticeUpdated', false);
      setValue('isRCPUpdated', false);
      setValue('communications', []);
      setValue('impact', 'Non');
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (!variation) return null;
    return initValues(variation);
  }, [variation]);

  // useLayoutEffect(() => {

  // }, [mainRef.current]);

  useEffect(() => {
    if (toast?.message === 'la variation a été supprimée') {
      history.push('/variation');
    }
  }, [toast]);

  return (
    <>
      {role === 'Admin' && <div className={styles['edit-toggle']}>
        {isEdit
          ? <Button
            handleClick={() => submitVariation()}
          >
            SAUVEGARDER
          </Button>
          : <Button
            handleClick={() => setIsEdit(true)}
          >
            EDITER
          </Button>
        }
      </div>
      }
      <div className={styles.container}>
        <div className={styles.content}>
          <aside>
            <Link className={styles.link} to={'/variation'}>
              <MdChevronLeft size={30} />  RETOUR À LA LISTE
            </Link>
            <Boolean
              label='Statut'
              options={statusOptions}
              isEdit={isEdit}
              className={'status'}
              handleClick={(val) => setValue('status', val)}
              value={watch('status')}
            />
            <Boolean
              label='ADC'
              isActive={true}
              className={'text-left'}
              options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
              isEdit={isEdit}
              handleClick={(val) => setValue('isADC', val)}
              value={watch('isADC')}
            />
            <Boolean
              label='Impact sur les éléments de communication'
              isActive={true}
              className={'text-left'}
              options={comImpactOptions}
              isEdit={isEdit}
              handleClick={(val) => setValue('impact', val)}
              value={watch('impact')}
            />

            <hr/>
            <Files
              control={control}
              isEdit={isEdit}
              submit={submitVariation}
            />
            <hr/>
            {isEdit && variation?._id && <button
              onClick={() => deleteVariationAction(dispatch, variation._id)}
              className={styles.delete}>
              <AiFillDelete size={20} /> Supprimer la variation
            </button>}
          </aside>
          <main
            style={{
              height: mainRef?.current?.clientHeight > 0 ? mainRef?.current?.clientHeight * 1.2 : 0,
            }}>
            <div ref={mainRef}>
              <ClockStops
                isEdit={isEdit}
                watch={watch}
                setValue={setValue}
                submit={submitVariation}
                control={control}
              />
              <Header
                isEdit={isEdit}
                drugsOptions={drugs?.map((d) => ({ value: d._id, label: d.name }))}
                watch={watch}
                setValue={setValue}
                control={control}
              />
              <DatesPrev
                isEdit={isEdit}
                watch={watch}
                setValue={setValue}
                control={control}
                />
              <Dates
                isEdit={isEdit}
                watch={watch}
                setValue={setValue}
                control={control}
              />
              <Details
                isEdit={isEdit}
                watch={watch}
                handleChangePackaging={handleChangePackaging}
                setValue={setValue}
                control={control}
              />
              <Comment
                isEdit={isEdit}
                watch={watch}
                setValue={setValue}
                control={control}
                submit={submitVariation}
              />
              <ComElements
                isEdit={role !== 'Member' ? true : isEdit }
                watch={watch}
                setValue={setValue}
                control={control}
                submit={submitVariation}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
