import React from 'react';
// import { format } from 'date-fns';
// import DatePicker from '../../../../lib/HooksFormFields/DatePicker';
import Textarea from '../../../../lib/HooksFormFields/Textarea';
import Boolean from '../../../../components/Boolean/Boolean';
import styles from './Details.module.scss';
// import InputText from '../../../../lib/HooksFormFields/InputText';

export default function Details({
  isEdit,
  watch,
  control,
  setValue,
  handleChangePackaging,
}) {
  function handleChangeRCP(val) {
    setValue('isRCPUpdated', val);
    // setValue('isAMMUpdated', val);
  }
  return (
    <section className={styles.container}>
      <h2>Détails</h2>
      <div className={styles['boolean-row']}>
        <div className={styles.col}>
          <Boolean
            label='RCP'
            isActive={true}
            options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
            isEdit={isEdit}
            handleClick={(val) => handleChangeRCP(val)}
            value={watch('isRCPUpdated')}
          />
        </div>
        <div className={styles.col}>
          <Boolean
            label='Packaging'
            isActive={true}
            options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
            isEdit={isEdit}
            handleClick={(val) => handleChangePackaging('isLabellingUpdated', val)}
            value={watch('isLabellingUpdated')}
          />
        </div>
        <div className={styles.col}>
          <Boolean
            label='Notice'
            isActive={true}
            options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
            isEdit={isEdit}
            handleClick={(val) => handleChangePackaging('isNoticeUpdated', val)}
            value={watch('isNoticeUpdated')}
          />
        </div>
        {/* <div className={styles.col}>
          <Boolean
            label='Impact'
            isActive={true}
            options={[{ value: 'Majeur', label: 'Majeur' }, { value: 'Mineur', label: 'Mineur' }]}
            isEdit={isEdit}
            handleClick={(val) => setValue('impact', val)}
            value={watch('impact')}
          />
        </div> */}
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <label>Nature du changement des ADC</label>
          {isEdit
            ? <div className={styles.select}>
                <Textarea
                name='change'
                control={control}
                className={'primary'}
                placeholder={'change'}
              />
            </div>
            : <pre>{watch('change')}</pre>
          }
        </div>
        {/* <div className={styles.col}>
          <label>Impact sur les éléments de communication</label>
          {isEdit
            ? <div className={styles.select}>
                <Textarea
                name='impactDescription'
                control={control}
                className={'primary'}
                placeholder={'change'}
              />
            </div>
            : <p>{watch('impactDescription')}</p>
          }
        </div> */}
      </div>
    </section>
  );
}
