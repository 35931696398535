import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineClockCircle, AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import DatePicker from '../../lib/FormField/DatePicker';
import styles from './ClockStopRow.module.scss';

export default function ClockStopRow({
  setValue,
  data,
  submit,
  index,
  isEdit,
  list,
}) {
  const [isSaved, setIsSaved] = useState(true);
  const { variation } = useSelector((state) => state.variations);

  function handleChange(key, value) {
    setIsSaved(false);
    const clockstops = list;
    clockstops[index] = { ...clockstops[index], [key]: value };
    setValue('clockstops', clockstops);
  }

  useEffect(() => {
    setIsSaved(true);
  }, [variation]);

  return (
    <div className={`${styles.container} ${isEdit ? styles['is-edit'] : ''}`}>
      <div className={styles.col}>
        <AiOutlineClockCircle size={30}/>
        <input
          value={data.name ? data.name : ''}
          onChange={(e) => handleChange('name', e.target.value)}
          type='text'
          />
      </div>
      <div className={styles.col}>
        <DatePicker
          label="Date d'arrêt"
          value={data.startDate ? new Date(data.startDate) : '' }
          onChange={(date) => handleChange('startDate', date?.toUTCString() || null)}
          clearDate={isEdit ? () => handleChange('startDate', null) : null}
          widthLabel={'35%'}
          />
      </div>
      <div className={styles.col}>
        <DatePicker
          label="Date de reprise prévisionnelle"
          value={data.previsionalEndDate ? new Date(data.previsionalEndDate) : null }
          onChange={(date) => handleChange('previsionalEndDate', date?.toUTCString() || null)}
          clearDate={isEdit ? () => handleChange('previsionalEndDate', null) : null}
          widthLabel={'35%'}
          />
      </div>
      <div className={styles.col}>
        <DatePicker
          label="Date de reprise réelle"
          value={data.endDate ? new Date(data.endDate) : null }
          onChange={(date) => handleChange('endDate', date?.toUTCString() || null)}
          clearDate={isEdit ? () => handleChange('endDate', null) : null}
          widthLabel={'35%'}
          />
      </div>
      {isEdit && <>
        <button
          className={`${styles.check} ${isSaved ? '' : styles['not-saved']}`}
          onClick={() => submit({ clockstops: list })}>
          <AiFillCheckCircle size={30} />
        </button>
        <button
          className={`${styles.check} ${!data._id ? '' : styles['not-saved']}`}
          onClick={() => submit({ clockstops: list.filter((c) => c._id !== data._id) })}>
          <AiFillCloseCircle size={30} />
        </button>
      </>}
    </div>
  );
}
