import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';

import { getVariationsListAction, getDrugsListAction } from '../../actions/variationsActions';

import Filters from '../../components/PlanningFilters/Filters';
import PlanningProductRow from '../../components/PlanningProductRow/PlanningProductRow';
import { statusOptions } from '../../utils/constants';

import styles from './Planning.module.scss';

export default function Planning() {
  const dispatch = useDispatch();
  const { variationsList, drugs, planningfilters } = useSelector((state) => state.variations);
  const today = new Date();
  const yearWidth = 1200;
  const monthRef = useRef();
  const contentRef = useRef();

  const [productsList, setProductsList] = useState();
  const [productsOptions, setProductsOptions] = useState();
  const [dates, setDates] = useState();
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });

  const initData = useCallback(() => {
    getVariationsListAction(dispatch);
    getDrugsListAction(dispatch);
  }, [dispatch]);

  function distBeetweenDates(first, second) {
    const numberOfDays = Math.round((second - first) / (1000 * 60 * 60 * 24));
    return numberOfDays * (yearWidth / 365);
  }

  function getMonth(d) {
    let isActiveMonth = false;
    const month = new Date(d);
    if (today.getFullYear() === month.getFullYear() && today.getMonth() === month.getMonth()) {
      isActiveMonth = true;
    }
    return ({
      className: `${styles.month} ${isActiveMonth ? styles['is-active'] : ''}`,
      isActive: isActiveMonth,
    });
  }

  function handleScroll(e) {
    setScrollPos({
      x: e.target.scrollLeft,
      y: e.target.scrollTop,
    });
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (monthRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        left: monthRef.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [monthRef.current, productsList]);

  useEffect(() => {
    if (!variationsList && !drugs) return null;
    // init dates
    let startDate = new Date();
    let endDate = new Date();
    const filteredVariation = variationsList?.filter((v) => {
      if (planningfilters.status.length > 0) {
        let found = false;
        planningfilters.status.forEach((key) => {
          if (v.status === key) found = true;
        });
        return found ? v : null;
      }
      return v;
    });
    filteredVariation?.forEach((v) => {
      const keys = ['firstSale', 'submissionRMS', 'validationANSM', 'validationRMS'];
      keys.forEach((k) => {
        if (v[k] && new Date(v[k]) < startDate) {
          startDate = new Date(v[k]);
        }
        if (v[k] && new Date(v[k]) > endDate) {
          endDate = new Date(v[k]);
        }
      });
      v?.clockstops.forEach((c) => {
        if (c.startDate && new Date(c.startDate) < startDate) {
          startDate = new Date(c.startDate);
        }
        if (c.endDate && new Date(c.endDate) > endDate) {
          endDate = new Date(c.endDate);
        }
      });
    });
    // init products
    const products = [];
    filteredVariation?.forEach((v) => {
      const productsVariations = [];
      filteredVariation.filter((va) => va.drug === v.drug).forEach((va) => {
        const steps = [];
        const previsionalSteps = [];
        let offset = 0;
        let vaDuration = {
          start: new Date(),
          end: null,
        };

        // CREATE REAL STEPS
        if (va.submissionRMS) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.submissionRMS),
          ) - 8;
          if (new Date(va.submissionRMS) <= vaDuration.start) {
            vaDuration.start = new Date(va.submissionRMS);
          }
          if (new Date(va.submissionRMS) >= vaDuration.end) {
            vaDuration.end = new Date(va.submissionRMS);
          }
          steps.push({
            _id: uniqid(),
            className: 'submition',
            label: 'Soumission RMS',
            variationId: va._id,
            date: new Date(va.submissionRMS),
            styles: { left: offset, width: 16 },
          });
        }

        if (va.validationRMS) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.validationRMS),
          ) - 8;
          if (new Date(va.validationRMS) <= vaDuration.start) {
            vaDuration.start = new Date(va.validationRMS);
          }
          if (new Date(va.validationRMS) >= vaDuration.end) {
            vaDuration.end = new Date(va.validationRMS);
          }
          steps.push({
            _id: uniqid(),
            className: 'date',
            label: 'Approbation RMS',
            variationId: va._id,
            date: new Date(va.validationRMS),
            styles: { left: offset, width: 16 },
          });
        }

        if (va.validationANSM) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.validationANSM),
          ) - 8;
          if (new Date(va.validationANSM) <= vaDuration.start) {
            vaDuration.start = new Date(va.validationANSM);
          }
          if (new Date(va.validationANSM) >= vaDuration.end) {
            vaDuration.end = new Date(va.validationANSM);
          }
          steps.push({
            _id: uniqid(),
            className: 'approbation-ANSM',
            label: 'Approbation ANSM',
            variationId: va._id,
            date: new Date(va.validationANSM),
            styles: { left: offset, width: 16 },
          });
        }

        va.clockstops?.forEach((c) => {
          offset = distBeetweenDates(new Date(startDate).setDate(1), new Date(c.startDate));
          const clockEnd = c.endDate || c.previsionalEndDate;
          if (clockEnd && c.startDate) {
            if (new Date(c.startDate) <= vaDuration.start) {
              vaDuration.start = new Date(c.startDate);
            }
            if (new Date(c.startDate) >= vaDuration.end) {
              vaDuration.end = new Date(c.startDate);
            }
            if (new Date(clockEnd) <= vaDuration.start) {
              vaDuration.start = new Date(clockEnd);
            }
            if (new Date(clockEnd) >= vaDuration.end) {
              vaDuration.end = new Date(clockEnd);
            }
            const clockstopWidth = distBeetweenDates(
              new Date(c.startDate),
              new Date(clockEnd),
            );
            steps.push({
              _id: uniqid(),
              className: 'clockstop',
              label: c?.name ? c.name : 'Clockstop',
              variationId: va._id,
              dates: {
                start: new Date(c.startDate),
                end: new Date(clockEnd),
              },
              styles: { left: offset, width: clockstopWidth },
            });
          }
        });

        offset = distBeetweenDates(new Date(startDate).setDate(1), new Date(vaDuration.start));
        let width = distBeetweenDates(new Date(vaDuration.start), new Date(vaDuration.end));
        steps.push({
          _id: uniqid(),
          className: 'duration',
          variationId: va._id,
          number: va.number,
          styles: { left: offset, width },
        });
        productsVariations.push(steps);

        offset = 0;
        vaDuration = {
          start: new Date(),
          end: null,
        };
        // CREATE PROVISIONAL STEPS
        if (va.submissionRMSPrevisional) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.submissionRMSPrevisional),
          ) - 8;
          if (new Date(va.submissionRMSPrevisional) <= vaDuration.start) {
            vaDuration.start = new Date(va.submissionRMSPrevisional);
          }
          if (new Date(va.submissionRMSPrevisional) >= vaDuration.end) {
            vaDuration.end = new Date(va.submissionRMSPrevisional);
          }
          previsionalSteps.push({
            _id: uniqid(),
            className: 'submition',
            label: 'Soumission RMS prévisionnelle',
            variationId: va._id,
            date: new Date(va.submissionRMSPrevisional),
            styles: { left: offset, width: 16 },
          });
        }

        if (va.validationRMSPrevisional) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.validationRMSPrevisional),
          ) - 8;
          if (new Date(va.validationRMSPrevisional) <= vaDuration.start) {
            vaDuration.start = new Date(va.validationRMSPrevisional);
          }
          if (new Date(va.validationRMSPrevisional) >= vaDuration.end) {
            vaDuration.end = new Date(va.validationRMSPrevisional);
          }
          previsionalSteps.push({
            _id: uniqid(),
            className: 'date',
            label: 'Approbation RMS prévisionnelle',
            variationId: va._id,
            date: new Date(va.validationRMSPrevisional),
            styles: { left: offset, width: 16 },
          });
        }

        if (va.validationANSMPrevisional) {
          offset = distBeetweenDates(
            new Date(startDate).setDate(1),
            new Date(va.validationANSMPrevisional),
          ) - 8;
          if (new Date(va.validationANSMPrevisional) <= vaDuration.start) {
            vaDuration.start = new Date(va.validationANSMPrevisional);
          }
          if (new Date(va.validationANSMPrevisional) >= vaDuration.end) {
            vaDuration.end = new Date(va.validationANSMPrevisional);
          }
          previsionalSteps.push({
            _id: uniqid(),
            className: 'approbation-ANSM',
            label: 'Approbation ANSM prévisionnelle',
            variationId: va._id,
            date: new Date(va.validationANSMPrevisional),
            styles: { left: offset, width: 16 },
          });
        }
        offset = distBeetweenDates(new Date(startDate).setDate(1), new Date(vaDuration.start));
        width = distBeetweenDates(new Date(vaDuration.start), new Date(vaDuration.end));
        if (previsionalSteps.length > 0) {
          previsionalSteps.push({
            _id: uniqid(),
            className: 'duration-prev',
            variationId: va._id,
            number: va.number,
            styles: { left: offset, width },
          });
          productsVariations.push(previsionalSteps);
        }
      });

      if (!products?.find((p) => p._id === v.drug) && drugs?.find((d) => d._id === v.drug)) {
        products.push({
          name: drugs?.find((d) => d._id === v.drug).name,
          _id: v.drug,
          variations: productsVariations,
        });
      }
    });

    if (filteredVariation?.length > 0) {
      const datesList = [];
      let date = new Date(startDate).setDate(1);
      do {
        datesList.push(new Date(date).toUTCString());
        date = new Date(date).setMonth(new Date(date).getMonth() + 1);
      } while (date < new Date(endDate).setMonth(new Date(endDate).getMonth() + 1));
      setDates(datesList);
    }

    setProductsOptions(products.map((p) => ({ value: p._id, label: p.name })));
    return setProductsList(products?.filter((p) => {
      if (planningfilters.products.length === 0) return p;
      if (planningfilters.products.find((s) => s === p._id)) return p;
      return null;
    }));
  }, [variationsList, drugs, planningfilters]);

  useEffect(() => {
    if (monthRef?.current) {
      contentRef.current.scrollTo({
        top: 0,
        left: monthRef?.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [monthRef?.current]);

  return (
    <>
      <Filters
        productsOptions={productsOptions}
        statusOptions={statusOptions}
        />
      <div className={styles.legend}>
        <div className={styles.item}>
          <div className={`${styles.prev}`}>
            <div className={`${styles['prev-item']}`}></div>
            <div className={`${styles['prev-item']}`}></div>
          </div>
          <p>Prévisionnel</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.reel}`}>
          </div>
          <p>Réel</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon}`}>
          </div>
          <p>Soumission</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.rms}`}>
          </div>
          <p>Approbation RMS</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.ansm}`}>
          </div>
          <p>Approbation ANSM
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content} ref={contentRef} onScroll={(e) => handleScroll(e)}>
          <div className={styles.dates} style={{ marginLeft: -(scrollPos.x) }}>
            {contentRef.current?.scrollHeight && dates?.map((d) => (
              <div
                key={d.toString()}
                ref={getMonth(d).isActive ? monthRef : null}
                style={{ width: yearWidth / 12 }}
                className={getMonth(d).className}>
                <p>{new Intl.DateTimeFormat('fr', { month: 'short' }).format(new Date(d))} {new Date(d).getFullYear()}</p>
              </div>
            ))}
          </div>
          {productsList && contentRef.current?.scrollHeight
          && <>
            <div className={styles.planning}>
              {productsList?.map((p) => <PlanningProductRow
                key={p._id}
                product={p}
                width={(yearWidth / 12) * dates.length}
              />)}
            </div>
            <div className={styles.products}>
              {productsList?.map((p) => <div
                key={p.name}
                style={{ height: p.variations.length * 70 }}
                className={styles.name}
              >
                {p.name}
              </div>)}
            </div>
          </>
          }
        </div>
      </div>
    </>
  );
}
