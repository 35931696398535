export default function sortByKey(a, b, key) {
  const textA = a[key].toLowerCase();
  const textB = b[key].toLowerCase();
  if (textA < textB) {
    return -1;
  } if (textA > textB) {
    return 1;
  }
  return 0;
}
