import React, { useEffect, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import {
  Switch, Route, useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../actions/authActions';

import Login from './Auth/Login';
import Toaster from '../lib/Toaster/Toaster';
import Nav from '../components/Nav';
import Index from '../pages/Auth/SwitchIndex';
import VariationsList from './VariationsList/VariationsList';
import Variation from './Variation/Variation';
import Planning from './Planning/Planning';

export default function GlobalRouter() {
  const { error } = useSelector((state) => state.variations);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)' },
  });
  const { toast } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function logout() {
    history.push('/login');
    localStorage.removeItem('token');
    dispatch({
      type: 'USER_AUTH',
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, []);

  useEffect(() => {
    console.log(error);
    if (error === 401) {
      history.push('/login');
    }
  }, [error]);

  return (
    <>
      <Nav
        logout={logout}
        />
      {transitions((props, item, key) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>

            <Route exact path='/' render={
              () => <Index />
            }/>

            <Route exact path='/login' render={
              () => <Login />
            }/>

            <Route exact path='/variation' render={
              () => <VariationsList />
            }/>

            <Route exact path='/variation/new' render={
              () => <Variation />
            }/>

            <Route exact path='/variation/:id' render={
              () => <Variation />
            }/>

            <Route exact path='/planning' render={
              () => <Planning />
            }/>

          </Switch>
        </animated.div>
      ))}
      <Toaster toast={toast} dispatch={dispatch}/>
    </>
  );
}
