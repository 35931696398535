import { saveAs } from 'file-saver';

import {
  getData, putData, postData, getFile, deleteData, OCTA_ID,
} from './index';

import {
  ERROR_VARIATIONS,
  GET_VARIATIONS,
  GET_VARIATION,
  PUT_VARIATION,
  POST_VARIATION,
  EXPORT_VARIATION,
  DELETE_VARIATION,
  GET_DRUGS,
  SET_TOAST,
  POST_FILE,
  DELETE_FILE,
} from './types';

export async function getVariationsListAction(dispatch) {
  const url = '/variation';
  let variations;
  await getData(ERROR_VARIATIONS, url, dispatch, true).then((response) => {
    variations = response.variations;
  });
  dispatch({
    type: GET_VARIATIONS,
    payload: variations,
  });
}

export async function getVariationAction(dispatch, id) {
  const url = `/variation/${id}`;
  let variation;
  await getData(ERROR_VARIATIONS, url, dispatch, true).then((response) => {
    variation = response.variation;
  });
  dispatch({
    type: GET_VARIATION,
    payload: variation,
  });
}

export async function putVariationAction(dispatch, id, data) {
  const url = `/variation/${id}`;
  let variation;

  await putData(PUT_VARIATION, ERROR_VARIATIONS, url, dispatch, data, true).then((response) => {
    variation = response.variation;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la variation a été modifié',
    },
  });

  dispatch({
    type: PUT_VARIATION,
    payload: variation,
  });
}

export async function postVariationAction(dispatch, data) {
  const url = '/variation';
  let variation;

  await postData(POST_VARIATION, ERROR_VARIATIONS, url, dispatch, data, true).then((response) => {
    variation = response.variation;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la variation a été créée',
    },
  });

  dispatch({
    type: POST_VARIATION,
    payload: variation,
  });
}

export async function deleteVariationAction(dispatch, id) {
  const url = `/variation/${id}`;
  let variation;

  await deleteData(ERROR_VARIATIONS, url, dispatch).then((response) => {
    variation = response;
  });
  if (variation) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'la variation a été supprimée',
      },
    });
    dispatch({
      type: DELETE_VARIATION,
      payload: id,
    });
  }
}

export async function postFileAction(dispatch, formData) {
  const url = '/file';
  let file;

  await postData(POST_FILE, ERROR_VARIATIONS, url, dispatch, formData, true).then((response) => {
    file = response;
  });

  dispatch({
    type: POST_FILE,
    payload: file.files[0],
  });
}

export async function deleteFileAction(dispatch, id) {
  const url = `/file/${ id}`;
  let file;

  await deleteData(ERROR_VARIATIONS, url, dispatch).then((response) => {
    file = response;
  });

  dispatch({
    type: DELETE_FILE,
    payload: {
      file: null,
      message: `${file.message} ${id}`,
    },
  });
}

export async function getFileAction(dispatch, id, downloadName = false, fileType = null) {
  const url = `/file/download/${id}`;

  await getFile(ERROR_VARIATIONS, url, dispatch, true).then((response) => {
    if (!downloadName && !fileType) {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    } else {
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      saveAs(blob, downloadName);
    }
  });
}

export async function getDrugsListAction(dispatch) {
  const url = `/drug?companyId=${OCTA_ID}`;
  let drugs;
  await getData(ERROR_VARIATIONS, url, dispatch, true).then((response) => {
    drugs = response.drugs;
  });
  dispatch({
    type: GET_DRUGS,
    payload: drugs,
  });
}

export async function exportVariationsAction(dispatch) {
  const url = '/variation/export';
  let exportData;
  await getData(ERROR_VARIATIONS, url, dispatch, true).then((response) => {
    exportData = response;
  });
  dispatch({
    type: EXPORT_VARIATION,
    payload: exportData,
  });
}
