import {
  ERROR_VARIATIONS,
  GET_VARIATIONS,
  GET_VARIATION,
  PUT_VARIATION,
  POST_VARIATION,
  DELETE_VARIATION,
  EXPORT_VARIATION,
  SET_VARIATION_FILTERS,
  SET_PLANNING_FILTERS,
  POST_FILE,
  GET_DRUGS,
  DELETE_FILE,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  variationsList: null,
  variation: null,
  drugs: null,
  exportData: null,
  planningfilters: {
    products: [],
    status: [],
  },
  variationsfilters: {
    search: null,
    relatedTo: [],
    safety: ['with', 'without'],
    status: [],
    products: [],
    impact: [],
  },
};

export default function variationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DRUGS:
      return { ...state, drugs: action.payload };
    case POST_FILE:
      return { ...state, file: action.payload };
    case SET_VARIATION_FILTERS:
      return { ...state, variationsfilters: action.payload };
    case SET_PLANNING_FILTERS:
      return { ...state, planningfilters: action.payload };
    case EXPORT_VARIATION:
      return { ...state, exportData: action.payload };
    case DELETE_FILE:
      return { ...state, file: null };
    case ERROR_VARIATIONS:
      return { ...state, error: action.payload };
    case POST_VARIATION:
      return { ...state, variation: action.payload };
    case DELETE_VARIATION:
      return {
        ...state,
        variationsList: state?.variationsList.filter((v) => v._id !== action.payload),
      };
    case GET_VARIATION:
      return { ...state, variation: action.payload };
    case PUT_VARIATION:
      return { ...state, variation: action.payload };
    case GET_VARIATIONS:
      return { ...state, variationsList: action.payload, variation: null };
    default:
      return { ...state };
  }
}
