import React from 'react';
import { AiOutlineDesktop } from 'react-icons/ai';
import styles from './MobilePage.module.scss';

export default function MobilePage() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <AiOutlineDesktop size={30} />
        </div>
        <div className={styles.message}>
          <p>Application uniquement disponible sur desktop</p>
        </div>
      </div>
    </div>
  );
}
