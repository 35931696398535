import React from 'react';
import sortByKey from '../../../../utils/utils';
import Boolean from '../../../../components/Boolean/Boolean';
import InputText from '../../../../lib/HooksFormFields/InputText';
import Textarea from '../../../../lib/HooksFormFields/Textarea';
import StyledSelect from '../../../../lib/FormField/Select';
import styles from './Header.module.scss';

export default function Header({
  isEdit,
  watch,
  control,
  setValue,
  drugsOptions,
}) {
  const typesOptions = ['IA', 'IAIN', 'IB', 'II', 'Renouvellement'].map((t) => ({ value: t, label: t }));
  return (
    <section className={styles.container}>
      <div className={styles.col}>
        <label>Numéro de la variation</label>
        {isEdit
          ? <InputText
            name='number'
            control={control}
            className={'number'}
            placeholder={'Numéro'}
            />
          : <h3>{watch('number')}</h3>
        }
        <label>Produit associé</label>
        {isEdit
          ? <div className={styles.select}>
            <StyledSelect
              options={drugsOptions ? drugsOptions.sort((a, b) => sortByKey(a, b, 'label')) : []}
              value={watch('drug')}
              handleChange={(val) => setValue('drug', val)}
             />
            </div>
          : <Boolean
            isActive={true}
            className={'width-auto'}
            options={drugsOptions}
            value={watch('drug')}
          />
        }
        <label>Type de la variation ou procédure</label>
        {isEdit
          ? <div className={styles.select}>
            <StyledSelect
              options={typesOptions}
              value={watch('type')}
              handleChange={(val) => setValue('type', val)}
             />
            </div>
          : <Boolean
            isActive={true}
            className={'width-auto'}
            options={typesOptions}
            value={watch('type')}
          />
        }
      </div>
      <div className={styles.col}>
        <Boolean
          label='Annexes AMM modifiées'
          isActive={true}
          options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
          isEdit={isEdit}
          className={'header'}
          handleClick={(val) => setValue('isAMMUpdated', val)}
          value={watch('isAMMUpdated')}
        />
        <Boolean
          label='AMM dérogatoire'
          isActive={true}
          options={[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]}
          isEdit={isEdit}
          className={'header'}
          handleClick={(val) => setValue('isDerogatoryAMM', val)}
          value={watch('isDerogatoryAMM')}
        />
      </div>
      <div className={styles.col}>
        <label>Variation Description</label>
        {isEdit
          ? <div className={styles.select}>
              <Textarea
              name='description'
              control={control}
              className={'primary'}
              placeholder={'description'}
            />
          </div>
          : <div className={styles.select}>
            <pre>{watch('description')}</pre>
          </div>
        }
      </div>
    </section>
  );
}
