import axios from 'axios';

const DEV_URL = 'https://api.octapharma.bakasable.io/api';
export const TOOL_ID = '61604fb05541500b16d424bd';
export const OCTA_ID = '61c33c8a671cf50d6bd8dba4';

export const API_URL = DEV_URL;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error, error.response);
  if (error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    dispatch({
      type,
      payload: error.response.status,
    });
  } else {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export function getData(errorType, url, dispatch, isAuthReq = false) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.get(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function getFile(errorType, url, dispatch, isAuthReq = false) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      responseType: 'blob',
      headers: {
        Authorization: token,
      },
    };

    axios.get(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(errorType, url, dispatch, isAuthReq = true) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function putData(action, errorType, url, dispatch, data, isAuthReq = true) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.put(requestUrl, data, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postData(action, errorType, url, dispatch, data, isAuthReq = false) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const config = {
      timeout: 1000 * 60,
    };
    const token = localStorage.getItem('token');

    if (isAuthReq) {
      config.headers = {
        Authorization: token,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}
