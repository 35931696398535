import React from 'react';
import uniqid from 'uniqid';

import Step from './Step/Step';

import styles from './PlanningProductRow.module.scss';

export default function PlanningProductRow({
  product,
  width,
}) {
  return (
    <div className={styles.container} style={{ height: product.variations.length * 70, width }}>
      {product.variations?.map((v) => (<div key={uniqid()} className={styles.variation}>
        {v.map((step) => <Step
          key={step._id}
          variation={step.variationId}
          stepStyles={step.styles}
          className={step.className}
          label={step.label}
          number={step?.number}
          dates={step.dates}
          date={step.date}
        />)}
      </div>))}
    </div>
  );
}
